/**
 * App.js
 *
 * @author Dima Dodonov <dimadodonov@icloud.com>
 */

//Global Vars
const $window = $(window);
const $document = $(document);
const $html = $('html');
const $wrapper = $('.wrapper');
const $header = $('.header');
const $main = $('.main');
const $overlay = $('.js-overlay');

const App = {
    init() {
        // console.log('Site init');
        this.product();
        this.nav();
        this.Components.init();
    },

    nav() {
        if ($('.menu-item-has-children').hasClass('dropdown')) {
            $('.menu-item-has-children.dropdown')
                .children('a')
                .wrap('<span class="dropdown-wrap"></span>');
            $('.dropdown-wrap').append('<span class="dropdown-btn"></span>');

            if ($(window).width() <= 480) {
                $('.dropdown-btn').on('click', function () {
                    $(this).parents('.dropdown').toggleClass('on');
                });
            } else {
                $('.menu-item-has-children.dropdown').on(
                    'mouseover',
                    function () {
                        $(this).find('.sub-menu').addClass('on');
                    }
                );

                $('.menu-item-has-children.dropdown').on(
                    'mouseout',
                    function () {
                        $(this).find('.sub-menu').removeClass('on');
                    }
                );
            }
        }
    },

    product() {
        // Добавляем перемещение при клике на плитки ароматов к якорю товара
        function if_window_width() {
            var wwidth = $(window).width();

            $('.product-shampoo-catalog-item').each(function (i, el) {
                var productLink = $(this);
                var newProductLink = $(this).attr('data-url');

                // if (wwidth <= 480) {
                //     productLink.attr(
                //         'href',
                //         newProductLink + '#product-shampoo-catalog-mob'
                //     );
                // } else {
                //     productLink.attr(
                //         'href',
                //         newProductLink + '#product-shampoo-catalog'
                //     );
                // }

                productLink.attr(
                    'href',
                    newProductLink + '#product-shampoo-catalog'
                );
            });
        }

        $(window).bind('resize', if_window_width);
        if_window_width();
    },
};

App.define = function (namespace) {
    var parts = namespace.split('.'),
        parent = App,
        i;

    //Убрать начальный префикс если это являетсся глобальной переменной
    if (parts[0] == 'App') {
        parts = parts.slice(1);
    }

    //Если в глобальном объекте нет свойства - создать его.
    for (var i = 0; i < parts.length; i++) {
        if (typeof parent[parts[i]] == 'undefined') {
            parent[parts[i]] = {};
        }
        parent = parent[parts[i]];
    }
    return parent;
};

/**

 * Components.js

 *

 * @author Dima Dodonov <dimadodonov@icloud.com>

 */



App.define('Components');



App.Components = (function () {

    function _init() {

        console.log('Site Components Init');

        App.Components.Menu.init();

        App.Components.Slider.init();

        App.Components.Fancybox.init();

        App.Components.Accordion.init();

        App.Components.ProductPopup.init();

        App.Components.Reviews.init();

        App.Components.EqualHeights.init();

        App.Components.Form.init();

        App.Components.Input.init();

        App.Components.Attention.init();

    }



    return {

        init: _init,

    };

})();

App.define('Components.Menu');



App.Components.Menu = (function () {

    function _init() {

        console.log('Site Components Menu Init');

        _initMenu();

    }



    function _initMenu() {

        const data = {

            name: 'Max',

            city: 'Moscow', // <-- traling comma ("висячая запятая")

        };



        $('#header__mob_menu').on('click', function () {

            if ($(this).hasClass('on')) {

                $(this).removeClass('on');

                $('.header__components').hide();

                $('.mob__nav.on_mob').hide();

                $('body').removeClass('is-fixed');

            } else {

                $(this).addClass('on');

                $('.header__components').show();

                $('.mob__nav.on_mob').show();

                $('body').addClass('is-fixed');

            }

        });

        $('#JsCloseMenu').on('click', function () {

            $('#header__mob_menu').removeClass('on');

            $('.header__components').hide();

            $('body').removeClass('is-fixed');

        });



        $('#header__mob_search').on('click', function () {

            if ($(this).hasClass('on')) {

                $('.header__components').hide();

                $('.mob__nav.on_mob').hide();

                $('body').removeClass('is-fixed');

            } else {

                $('.header__components').show();

                $('.mob__nav.on_mob').show();

                $('body').addClass('is-fixed');

            }

        });

    }



    return {

        init: _init,

        initMenu: _initMenu,

    };

})();



App.define('Components.Slider');



App.Components.Slider = (function () {

    function _init() {

        console.log('Site Components Slider Init');

        _initSlider();

    }



    function _initSlider() {

        $('.section__slider').slick({

            dots: true,

            infinite: true,

            speed: 300,

            slidesToShow: 1,

            // fade: true,

            adaptiveHeight: false,

            prevArrow: $('.slide-btn-prev'),

            nextArrow: $('.slide-btn-next'),

        });



        $('#product_images_slider').slick({

            slidesToShow: 1,

            slidesToScroll: 1,

            arrows: false,

            // infinite: true,

            // fade: true,

            asNavFor: '#product_images_slider_nav',

            prevArrow: $('.slide-btn-prev'),

            nextArrow: $('.slide-btn-next'),

            responsive: [

                {

                    breakpoint: 480,

                    settings: {

                        slidesToShow: 1,

                        slidesToScroll: 1,

                        dots: true,

                        arrows: true,

                        infinite: true,

                    },

                },

                // You can unslick at a given breakpoint now by adding:

                // settings: "unslick"

                // instead of a settings object

            ],

        });

        $('#product_images_slider_nav').slick({

            slidesToShow: 4,

            slidesToScroll: 1,

            // centerMode: true,

            // centerPadding: '20px',

            asNavFor: '#product_images_slider',

            dots: false,

            infinite: true,

            focusOnSelect: true,

            prevArrow: $('.slide-btn-prev'),

            nextArrow: $('.slide-btn-next'),

            responsive: [

                {

                    breakpoint: 480,

                    settings: {

                        slidesToShow: 1,

                        slidesToScroll: 1,

                        dots: true,

                    },

                },

                // You can unslick at a given breakpoint now by adding:

                // settings: "unslick"

                // instead of a settings object

            ],

        });

    }



    return {

        init: _init,

        initSlider: _initSlider,

    };

})();



App.define('Components.Fancybox');



App.Components.Fancybox = (function () {

    function _init() {

        console.log('Site Components Fancybox Init');

        _initFancybox();

    }



    function _initFancybox() {

        $('[data-fancybox]').fancybox({

            touch: false,

            // toolbar: false,

            hash: false,

            clickSlide: false,

            clickOutside: 'close',

            buttons: [

                //"zoom",

                //"share",

                //"slideShow",

                //"fullScreen",

                //"download",

                //"thumbs",

                'close',

            ],

            loop: true,

            protect: false, // РїРѕР»СЊР·РѕРІР°С‚РµР»СЊ РЅРµ РјРѕР¶РµС‚ СЃРѕС…СЂР°РЅРёС‚СЊ РёР·РѕР±СЂР°Р¶РµРЅРёРµ

            infobar: false,

            mobile: {

                clickContent: 'close',

                clickSlide: 'close',

            },

            lang: 'ru',

            i18n: {

                ru: {

                    CLOSE: 'Закрыть',

                    NEXT: 'Следующий',

                    PREV: 'Предыдущий',

                    ERROR: 'Ошибка',

                },

            },

        });

    }



    return {

        init: _init,

        initFancybox: _initFancybox,

    };

})();



App.define('Components.Accordion');



App.Components.Accordion = (function () {

    function _init() {

        console.log('Site Components Accordion Init');

        _initAccordion();

    }



    function _initAccordion() {

        var accordeon = function () {

            var data = $('.accordion').attr('data-accordion');



            $('.accordeon-header').on('click', function () {

                if (data === 'close') {

                    $('.accordeon-body').slideUp();

                    if ($(this).hasClass('active')) {

                        $(this).toggleClass('active');

                    } else {

                        $('.accordeon-header').removeClass('active');

                    }

                } else {

                    $(this).toggleClass('active');

                }

                $(this).next('.accordeon-body').not(':animated').slideToggle();

            });



            if ($(window).width() <= 480) {

                $('#secondary').attr('data-accordion', 'close');



                $('.bapf_head').on('click', function () {

                    if (

                        $(this).parent('.bapf_sfilter').hasClass('bapf_ccolaps')

                    ) {

                        $(this).next('.bapf_body').slideUp();

                        // alert('Yes');

                    } else {

                        $(this)

                            .next('.bapf_body')

                            .animate({ height: 'show' }, 500);

                    }

                });

            }

        };



        function scrollToAnchor(aid) {

            var aTag = $("a[name='" + aid + "']");

            $('html,body').animate({ scrollTop: aTag.offset().top }, 'slow');

        }



        $(document).on('click', '.bapf_update', function () {

            scrollToAnchor('widgets__ancor');

        });



        accordeon();

    }



    return {

        init: _init,

        initAccordion: _initAccordion,

    };

})();



//Product Popoup

App.define('Components.ProductPopup');



App.Components.ProductPopup = (function () {

    function _init() {

        console.log('Site Components ProductPopup Init');

        _initProductPopup();

    }



    function _initProductPopup() {

        $('.jsPopupShopsOnline').on('click', function () {

            $('.popup_overlay').show();

            $('.popup').show();

            $('body').addClass('is-fixed');

        });

        $('.jsPopupShopsOffline').on('click', function () {

            localStorage.setItem('typeShops', 'offline');

        });

        $('.jsClosePopup').on('click', function () {

            $('.popup_overlay').hide();

            $('.popup').hide();

            $('body').removeClass('is-fixed');

        });

    }



    return {

        init: _init,

        initProductPopup: _initProductPopup,

    };

})();



App.define('Components.Reviews');



App.Components.Reviews = (function () {

    function _init() {

        console.log('Site Components Reviews Init');

        _initReviews();

    }



    function _initReviews() {

        $('.reviews__item_content_p_btn').on('click', function () {

            $(this).prev().addClass('readmore');

            $(this).hide();

        });

    }



    return {

        init: _init,

        initReviews: _initReviews,

    };

})();



// EqualHeights

App.define('Components.EqualHeights');



App.Components.EqualHeights = (function () {

    function _init() {

        console.log('Site Components EqualHeights Init');

        _initEqualHeights();

    }



    function _initEqualHeights() {

        //equalHeight

        function heightses() {

            $('.woocommerce-loop-product__title').height('auto').equalHeights();

        }

        heightses();



        $(window).on('load', function () {

            heightses();

        });



        $(window).resize(function () {

            heightses();

        });

    }



    return {

        init: _init,

        initEqualHeights: _initEqualHeights,

    };

})();



App.define('Components.Form');



App.Components.Form = (function () {

    function _init() {

        console.log('Site Components Form Init');

        _initForm();

    }



    function _initForm() {

        $('input').focus(function () {

            $(this).parents('.input__wrap').addClass('isFocused');

        });



        $('input').blur(function () {

            var inputValue = $(this).val();

            if (inputValue == '') {

                $(this).removeClass('isFilled');

                $(this).parents('.input__wrap').removeClass('isFocused');

            } else {

                $(this).addClass('isFilled');

            }

        });

    }



    return {

        init: _init,

        initForm: _initForm,

    };

})();



App.define('Components.Input');



App.Components.Input = (function () {

    function _init() {

        console.log('Site Components Input Init');

        _initMask();

    }



    function _initMask() {

        //Masked inputmask https://github.com/RobinHerbots/Inputmask



        var inputPhone = $('.js-phone-mask');



        if (inputPhone) {

            inputPhone.inputmask('+7 (999) 999-99-99', {

                showMaskOnHover: true,

            }); //default

        }



        // Форма возврат средств



        function moneyBack() {

            let filedTypePhone = document.querySelector(

                '.wpcf7-checkbox .first input'

            );

            let filedTypeBank = document.querySelector(

                '.wpcf7-checkbox .last input'

            );

            let fieldPhoneMob = document.querySelector('.field_phone_mob');



            if (filedTypePhone) {

                // Присваеваем первому объекту checked

                filedTypePhone.setAttribute('checked', 'checked');

            }



            $('.wpcf7-checkbox .first input').change(function () {

                $('.jsOptionPhone').show();

                $('.jsOptionBank').hide();

            });



            $('.wpcf7-checkbox .last input').change(function () {

                $('.jsOptionPhone').hide();

                $('.jsOptionBank').show();

            });



            $('.js-phone-one').on('keyup', function () {

                let phoneNumberfield = $('.js-phone-one').val();

                if (phoneNumberfield.length < 3) {

                    return false;

                }

                console.log(phoneNumberfield);



                $('.js-phone-two').val(phoneNumberfield);

            });

        }



        moneyBack();



        document.addEventListener(

            'wpcf7mailsent',

            function (event) {

                $('.jsHideForm').hide();

                $('.jsShowForm').show();

            },

            false

        );

    }



    return {

        init: _init,

        initMask: _initMask,

    };

})();



App.define('Components.Attention');



App.Components.Attention = (function () {

    function _init() {

        console.log('Site Components Attention Init');

        _initAttention();

    }



    function _initAttention() {

        if (localStorage.getItem('attention') !== null) {

            const attention = localStorage.getItem('attention');

            $('.js-attention').hide();

        } else {

            $('.js-attention').show();

        }

        $('.js-attention .js-attention-close').click(function () {

            localStorage.setItem('attention', 'yes');

            $('.js-attention').hide();

        });

    }



    return {

        init: _init,

        initAttention: _initAttention,

    };

})();



$(function () {



    $("#searchform").bind('keypress keydown keyup', function (e) {



        if (e.keyCode === 13) { e.preventDefault(); }



    });







    $('.searchform input[name="s"]').on('keyup', function () {



        var search = $('.searchform input[name="s"]').val();



        if (search.length < 3) {



            return false;



        }



        var data = {



            s: search,



            action: 'search_ajax',



            nonce: search_form.nonce



        };



        $.ajax({



            url: search_form.ajaxurl,



            data: data,



            type: 'POST',



            dataType: 'json',



            beforeSend: function (xhr) {



                console.log('Ищем...');



            },



            success: function (data) {



                $('.ajaxSearchResult').show();



                $('#searchsubmit').hide();



                $('#clearsubmit').show();



                if (data.out) {



                    $('.ajaxSearchResult').html(data.out);



                }



            }



        });



        console.log(search);



    });







    $(document).mouseup(function (e) { // событие клика по веб-документу



        var div = $(".header__components_search"); // тут указываем ID элемента



        if (!div.is(e.target) // если клик был не по нашему блоку



            && div.has(e.target).length === 0) { // и не по его дочерним элементам



            $('.ajaxSearchResult').hide();



            $('.ajaxSearchResult').empty();



            // $('.searchform input[name="s"]').val('').change();



        }



    });



});

$(function() {

    $('.btn_add_reviews_data').on('click', function() {

        const reviewsDataID = $('.btn_add_reviews_data').attr(

            'data-product-id'

        );

        const reviewsDataName = $('.btn_add_reviews_data').attr(

            'data-product-name'

        );



        localStorage.setItem('reviewsDataID', reviewsDataID);

        localStorage.setItem('reviewsDataName', reviewsDataName);

    });



    if (localStorage.getItem('reviewsDataID') !== null) {

        $('.reviewsDataID').text(localStorage.getItem('reviewsDataName'));

        $('.reviews_title_product').val(

            localStorage.getItem('reviewsDataName')

        );

    }

    if (localStorage.getItem('reviewsDataName') !== null) {

        $('.reviews_id_post').val(localStorage.getItem('reviewsDataID'));

    }



    if ($('.reviews_stars_field')) {

        $('.stars_one').mouseover(function() {

            $(this).addClass('on');

        });

        $('.stars_one').mouseout(function() {

            $(this).removeClass('on');

        });



        $('.stars_two').mouseover(function() {

            $('.stars_one').addClass('on');

            $(this).addClass('on');

        });

        $('.stars_two').mouseout(function() {

            $('.stars_one').removeClass('on');

            $(this).removeClass('on');

        });



        $('.stars_tree').mouseover(function() {

            $('.stars_one').addClass('on');

            $('.stars_two').addClass('on');

            $(this).addClass('on');

        });

        $('.stars_tree').mouseout(function() {

            $('.stars_one').removeClass('on');

            $('.stars_two').removeClass('on');

            $(this).removeClass('on');

        });



        $('.stars_four').mouseover(function() {

            $('.stars_one').addClass('on');

            $('.stars_two').addClass('on');

            $('.stars_tree').addClass('on');

            $(this).addClass('on');

        });

        $('.stars_four').mouseout(function() {

            $('.stars_one').removeClass('on');

            $('.stars_two').removeClass('on');

            $('.stars_tree').removeClass('on');

            $(this).removeClass('on');

        });



        $('.stars_five').mouseover(function() {

            $('.stars_one').addClass('on');

            $('.stars_two').addClass('on');

            $('.stars_tree').addClass('on');

            $('.stars_four').addClass('on');

            $(this).addClass('on');

        });

        $('.stars_five').mouseout(function() {

            $('.stars_one').removeClass('on');

            $('.stars_two').removeClass('on');

            $('.stars_tree').removeClass('on');

            $('.stars_four').removeClass('on');

            $(this).removeClass('on');

        });



        $('.stars_one').on('click', function() {

            $('#reviews_stars').val('1');

            $(this).addClass('active');

            $('.stars_two').removeClass('active');

            $('.stars_tree').removeClass('active');

            $('.stars_four').removeClass('active');

            $('.stars_five').removeClass('active');

        });



        $('.stars_two').on('click', function() {

            $('#reviews_stars').val('2');

            $(this).addClass('active');

            $('.stars_one').addClass('active');

            $('.stars_tree').removeClass('active');

            $('.stars_four').removeClass('active');

            $('.stars_five').removeClass('active');

        });



        $('.stars_tree').on('click', function() {

            $('#reviews_stars').val('3');

            $(this).addClass('active');

            $('.stars_one').addClass('active');

            $('.stars_two').addClass('active');

            $('.stars_four').removeClass('active');

            $('.stars_five').removeClass('active');

        });



        $('.stars_four').on('click', function() {

            $('#reviews_stars').val('4');

            $(this).addClass('active');

            $('.stars_one').addClass('active');

            $('.stars_two').addClass('active');

            $('.stars_tree').addClass('active');

            $('.stars_five').removeClass('active');

        });



        $('.stars_five').on('click', function() {

            $('#reviews_stars').val('5');

            $(this).addClass('active');

            $('.stars_one').addClass('active');

            $('.stars_two').addClass('active');

            $('.stars_tree').addClass('active');

            $('.stars_four').addClass('active');

        });

    }

});



jQuery(function($) {

    $('#true_loadmore').on('click', function() {

        $('#true_loadmore .btn').text('Загружаю...'); // изменяем текст кнопки, вы также можете добавить прелоадер

        var data = {

            action: 'load_reviews',

            query: true_posts,

            product: product_id,

            page: current_page,

        };

        $.ajax({

            url: ajaxurl, // обработчик

            data: data, // данные

            type: 'POST', // тип запроса

            success: function(data) {

                if (data) {

                    $('#true_loadmore .btn').text('Показать ещё'); // вставляем новые посты

                    $('#true_loadmore').before(data); // вставляем новые посты

                    current_page++; // увеличиваем номер страницы на единицу

                    if (current_page == max_pages) $('#true_loadmore').remove(); // если последняя страница, удаляем кнопку

                } else {

                    $('#true_loadmore .btn').remove(); // если мы дошли до последней страницы постов, скроем кнопку

                }

            },

        });

    });



    $(document).on('click', '#load_city_shops', function() {

        $('#load_city_shops .btn').text('Загружаю...'); // изменяем текст кнопки, вы также можете добавить прелоадер

        var data = {

            action: 'load_city_shops',

            query: true_posts,

            page: current_page,

        };

        $.ajax({

            url: ajaxurl, // обработчик

            data: data, // данные

            type: 'POST', // тип запроса

            success: function(data) {

                if (data) {

                    $('#load_city_shops .btn').text('Показать ещё'); // вставляем новые посты

                    $('#load_city_shops').before(data); // вставляем новые посты

                    current_page++; // увеличиваем номер страницы на единицу

                    if (current_page == max_pages)

                        $('#load_city_shops').remove(); // если последняя страница, удаляем кнопку

                } else {

                    $('#load_city_shops .btn').remove(); // если мы дошли до последней страницы постов, скроем кнопку

                }

            },

        });

    });

});





$(function () {
    App.init();
    svg4everybody();
});
